<template>
	<div class="h-screen flex overflow-hidden mt-6 bg-white rounded-md shadow-md">
		<div class="flex flex-col min-w-0 flex-1 overflow-hidden">		
			<div class="flex-1 relative z-0 flex overflow-hidden">
				<main class="flex-1 relative z-0 overflow-y-auto focus:outline-none xl:order-last">
					<!-- Breadcrumb -->
					<nav class="flex items-start px-4 pt-6 pb-1 sm:px-6 lg:px-8 xl:hidden" aria-label="Breadcrumb">
						<div class="inline-flex items-center space-x-3 text-sm font-medium text-accent cursor-pointer" @click="showList">
							<ChevronLeftIcon class="-ml-2 h-5 w-5" aria-hidden="true" />
							<span>Usuarios</span>
						</div>
					</nav>

					<article class="text-sm text-gray-400 p-12" v-if="state.profile==null">
						<HandIcon class="h-4 w-4 mr-1 text-gray-400 inline-block" aria-hidden="true" />
						Selecciona un usuario de la columna izquierda.
					</article>

					<article v-if="state.profile">
						<!-- Profile header -->
						<div class="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
							<div class="sm:flex sm:items-end sm:space-x-5">
								<div class="mt-6 sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
									<div class="sm:hidden 2xl:block mt-6 min-w-0 flex-1">
										<h1 class="text-2xl font-bold text-accent truncate">
											{{ state.profile.name }}
										</h1>
										<h2 class="text-sm text-gray-500">
											{{ state.profile.email }}
										</h2>
									</div>
									<div class="mt-6 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4" v-if="state.profile.role == 'user' && !state.sending">
										<button type="button" @click="autorizar(state.profile, true)" v-if="state.profile.pending" class="inline-flex justify-center px-4 py-2 border border-green-300 shadow-sm text-sm font-medium rounded-md text-green-50  bg-green-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:green-500">
											<CheckIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
											<span>Autorizar</span>
										</button>
										<button type="button" @click="autorizar(state.profile, false)" v-if="!state.profile.pending" class="inline-flex justify-center px-4 py-2 border border-red-300 shadow-sm text-sm font-medium rounded-md text-red-500 h0ver:text-red-50 bg-white  hover:bg-red-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:red-500">
											<XIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
											<span>Desautorizar</span>
										</button>
									</div>
								</div>
							</div>
							<div class="hidden sm:block 2xl:hidden mt-6 min-w-0 flex-1">
								<h1 class="text-2xl font-bold text-gray-900 truncate">
									{{ state.profile.name }}
								</h1>
								<h2 class="text-sm text-gray-500">
									{{ state.profile.email }}
								</h2>
							</div>
						</div>

						<div class="ml-8 mt-1 text-sm" v-if="state.profile.pending">
							<ClockIcon class="h-5 w-5 mr-1 text-yellow-500 inline-block" aria-hidden="true" />
							<span class="font-semibold text-yellow-500 text-xs">Pendiente de autorización</span>
						</div>

						<!-- Tabs -->
						<div class="mt-6 sm:mt-2 2xl:mt-5">
							<div class="border-b border-gray-200">
								<div class="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
									<nav class="-mb-px flex space-x-8" aria-label="Tabs">
										<a v-for="tab in tabs" :key="tab.name" :href="tab.href" :class="[tab.current ? 'border-pink-500 text-gray-900' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300', 'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm']" :aria-current="tab.current ? 'page' : undefined">
											{{ tab.name }}
										</a>
									</nav>
								</div>
							</div>
						</div>

						<!-- Description list -->
						<div class="mt-6 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8" v-if="state.profile.role == 'user'">
							<dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
								<div v-for="field in Object.keys(state.profile.fields)" :key="field" class="sm:col-span-1">
									<dt class="text-sm font-medium text-gray-500">
										{{ field }}
									</dt>
									<dd class="mt-1 text-sm text-black">
										{{ state.profile.fields[field] }}
									</dd>
								</div>
								<div class="sm:col-span-2">
									<dt class="text-xs font-medium text-primary uppercase mt-6">
										Puntos acumulados
									</dt>
									<dd class="mt-1 text-sm text-gray-900">
										{{state.profile.gamePoints + state.profile.casePoints}} pts.
									</dd>

									<dt class="text-xs font-medium text-primary uppercase mt-6">
										Puntos del juego
									</dt>
									<dd class="mt-1 text-sm text-gray-900">
										{{state.profile.gamePoints}} pts.
									</dd>

									<dt class="text-xs font-medium text-primary uppercase mt-6">
										Caso clínico ({{state.profile.casePoints}} pts.)
									</dt>
									<dd class="mt-2 text-sm text-gray-900">
										<ul class="border border-gray-200 rounded-md divide-y divide-gray-200">
											<li class="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
												<template v-if="state.profile.case">
													<div class="w-0 flex-1 flex items-center">
														<PaperClipIcon class="flex-shrink-0 h-5 w-5 text-primary" aria-hidden="true" />
														<span class="ml-2 flex-1 w-0 truncate">
															{{state.profile.case.title}}
														</span>
													</div>
													<div class="ml-4 flex-shrink-0">
														<a :href="state.profile.case.url" target="_blank" class="font-medium text-accent hover:text-primary">
															Descargar
														</a>
													</div>
												</template>
												<div class="w-0 flex-1 flex items-center" v-if="state.profile.case == undefined">
													<BanIcon class="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
													<span class="ml-2 flex-1 w-0 truncate">Aún no registra caso clínico.</span>
												</div>
											</li>
										</ul>
									</dd>

									<dt class="text-xs font-medium text-primary uppercase mt-8">
										Fecha de registro
									</dt>
									<dd  class="mt-1 text-sm text-gray-900">
										{{state.profile.createdAt}}
									</dd>
								</div>
							</dl>
						</div>

						<div class="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8" v-if="state.profile.role == 'staff'">
							<dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
								<div class="sm:col-span-2">
									<dt class="text-xs font-semibold text-accent uppercase mt-8">
										Usuario Staff
									</dt>
									<dd class="mt-1 text-sm text-gray-900">
										
									</dd>

									<dt class="text-xs font-medium text-primary uppercase mt-8">
										Fecha de registro
									</dt>
									<dd  class="mt-1 text-sm text-gray-900">
										{{state.profile.createdAt}}
									</dd>
								</div>
							</dl>
						</div>
					</article>
				</main>
				<aside class="hidden lg:order-first lg:flex lg:flex-col flex-shrink-0 w-96 border-r border-gray-200">
					<div class="px-6 pt-6 pb-4">
						<div class="flex items-center justify-between">
							<div>
								<h2 class="text-lg font-medium text-primary">
									<span v-if="!state.onlyPendingUsers">Todos los usuarios</span>
									<span v-else>Usuarios pendientes</span>
								</h2>
							</div>
							<div>
								<button class="mt-0.5 text-xs flex font-semibold items-center space-x-1 text-green-500 hover:text-accent focus:outline-none" @click="downloadFile">
									<DocumentDownloadIcon class="w-4 h-4" />
									<div>DESCARGAR</div>
								</button>
							</div>
						</div>
						<div class="mt-1 text-xs text-gray-600 flex items-center">
							<div class="mr-1">Autorizados:</div>
							<div class="font-semibold text-sm text-accent">{{state.totalAuthorized}}</div>  
							<!-- <div class="mx-2">|</div>  -->
							<div class="ml-4 mr-1">Pendientes:</div>
							<div class="font-semibold text-sm text-accent">{{state.totalPending}}</div> 
						</div>
						<form class="mt-6 flex space-x-4" action="#">
							<div class="flex-1 min-w-0">
								<label for="search" class="sr-only">Search</label>
								<div class="relative rounded-md shadow-sm">
									<div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
										<SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
									</div>
									<input v-model="state.search" type="search" name="search" autocomplete="off" id="search" class="focus:ring-pink-500 focus:border-pink-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md" placeholder="Búsqueda por correo" />
								</div>
							</div>
						</form>
						<div class="text-xs cursor-pointer ml-2 mt-4 text-primary" @click="state.onlyPendingUsers = !state.onlyPendingUsers">
							<CollectionIcon class="h-4 w-4 mr-2 text-primary inline-block" aria-hidden="true" />
							<span v-if="state.onlyPendingUsers">Ver todos los usuarios</span>
							<span v-else>Ver solo usuarios pendientes</span>
						</div>
					</div>
					<!-- Usuarios list -->
					<nav class="flex-1 min-h-0 overflow-y-auto" aria-label="Usuarios">
						<ul class="relative z-0 divide-y divide-gray-200" v-if="listUsers.length > 0">
							<li v-for="person in listUsers" :key="person.id">
								<div class="relative px-6 py-5 flex items-center space-x-3 focus-within:ring-2 focus-within:ring-inset focus-within:ring-pink-500 cursor-pointer" :class="(state.profile && state.profile.id == person.id)  ? 'bg-accent' : 'bg-white'">
									<div class="flex-1 min-w-0">
										<div @click="setShow(person)" v-if="person.name">
											<!-- Extend touch target to entire panel -->
											<span class="absolute inset-0" aria-hidden="true" />
											<p class="text-sm font-medium truncate" :class="(state.profile && state.profile.id == person.id)  ? 'text-white' : 'text-gray-500'">
												<ClockIcon class="h-5 w-5 mr-1 text-yellow-500 inline-block" aria-hidden="true" v-if="person.pending"/>
												{{ person.name }} {{person.paterno}} <span v-if="person.materno">{{person.materno}}</span> <span class="text-xs italic" v-if="person.role=='staff'">(Staff)</span>
											</p>
											<p class="text-sm truncate" :class="(state.profile && state.profile.id == person.id)  ? 'text-white opacity-50' : 'text-gray-500'">
												{{ person.email }}
											</p>
										</div>

										<div v-else>
											<!-- Extend touch target to entire panel -->
											<span class="absolute inset-0" aria-hidden="true" />
											<p class="text-sm font-medium text-gray-400 truncate">
												[ Falta completar registro ]
											</p>
											<p class="text-sm text-gray-500 truncate">
												{{ person.email }}
											</p>
										</div>
									</div>
								</div>
							</li>
						</ul>

						<div class="py-8 px-8 text-gray-400 text-xs flex items.center" v-if="listUsers.length == 0">
							<BanIcon class="h-5 w-5 mr-2 text-gray-400 inline-block" aria-hidden="true" />
							<div>No hay usuarios bajo los parámetros seleccionados.</div>
						</div>
					</nav>
				</aside>
			</div>
		</div>
	</div>
</template>

<script>
import { onMounted, reactive, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { Dialog, DialogOverlay, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { MenuIcon, PaperClipIcon, BanIcon, XIcon, CheckIcon, DocumentDownloadIcon } from '@heroicons/vue/outline'
import { ChevronLeftIcon, FilterIcon, MailIcon, PhoneIcon, SearchIcon, ClockIcon, CollectionIcon, HandIcon } from '@heroicons/vue/solid'
import _ from 'lodash'
import Users from '@/firebase/users'
import Helpers from '@/services/helpers'
import Misc from '@/services/misc'
import router from '../../router'

const tabs = [
	{ name: 'Información', href: '#', current: true }
]

export default {
	components: {
		Dialog,
		DialogOverlay,
		TransitionChild,
		TransitionRoot,
		ChevronLeftIcon,
		FilterIcon,
		MailIcon,
		MenuIcon,
		PhoneIcon,
		SearchIcon,
		PaperClipIcon,
		XIcon,
		BanIcon,
		CheckIcon,
		ClockIcon,
		CollectionIcon,
		HandIcon,
		DocumentDownloadIcon
	},
	setup() {
		const store = useStore();
		const router = useRouter();

		const user = computed(() => store.getters.user)

		const state = reactive({
			users: [],
			profile: null,
			sending: false, 
			onlyPendingUsers: false,
			totalPending: 0,
			totalAuthorized: 0,
			search: ''
		});

		onMounted(() => {
			if (user.value.role != 'staff') {
				router.push('/')
			} else {
				init()
			}
		});

		function init() {
			Users.getAll()
				.then(query => {
					if (query) {
						state.users = Helpers.formatItems(query);
						setTotals();
					} else {
						store.dispatch('setNotification', {type: false, content: 'Error de comunicación.'});
					}
				});
		}

		const listUsers = computed(() => {
			let tempo = [];

			if (!state.onlyPendingUsers) {
				tempo = state.users;
			} else {
				state.users.forEach(dt => {
					if (dt.pending) {
						tempo.push(dt);
					}
				});
			}

			return tempo.filter(item => { 
				if (item.name) {
					return item.email.toLowerCase().includes(state.search.toLowerCase());
				} else {
					return item;
				}
			});
			
		});

		function setShow(person) {
			let name = person.name + ' ' + person.paterno;

			if (person.materno) {
				name += ' ' + person.materno;
			}

			state.profile = {
				id: person.id,
				name: name,
				email: person.email,
				role: person.role,
				pending: person.pending,
				gamePoints: person.gamePoints,
				casePoints: person.casePoints,
				answers: person.answers,
				createdAt: person.createdAt.toDate().toLocaleDateString('es-ES', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}) + ' a las ' + person.createdAt.toDate().toLocaleTimeString('es-MX')
			}

			state.profile.createdAt = Misc.capitalizeFirstLetter(state.profile.createdAt);

			if (person.case) {
				state.profile.case = person.case;
			}

			if (person.role == 'user') {
				state.profile.fields = {
					['Cédula']: person.cedula,
					['Institución']: person.institucion,
					['Teléfono']: Misc.formatPhone(person.telefono),
					['Especialidad']: Misc.especialidades.find(item => {return item.id == person.especialidad}).text,
					['Grado']: Misc.grados.find(item => {return item.id == person.grado}).text,
					['Estado Actual']: Misc.estados.find(item => {return item.id == person.estadoActual}).text,
					['Estado de nacimiento']: Misc.estados.find(item => {return item.id == person.estadoNatal}).text
				};
			}
		}

		function setTotals() {
			state.totalPending = 0;
			state.totalAuthorized = 0;

			state.users.forEach(dt => {
				if (dt.pending) {
					state.totalPending++;
				}
				if (!dt.pending && dt.name) {
					state.totalAuthorized++;
				}
			});
		}

		function autorizar(profile, est) {
			state.sending = true;

			Users.updatePending(profile, {pending: !est})
				.then(response => {
					if (response) {
						state.profile.pending = !est;
						let us = state.users.find(item => {return item.id == state.profile.id});
						us.pending = !est;
						setTotals();

						store.dispatch('setNotification', {type: true, content: 'Acceso de usuario actualizado.'});
					} else {
						store.dispatch('setNotification', {type: false, content: 'Error de comunicación.'});
					}
					state.sending = false;
				});
		}

		const downloadFile = () => {
			const items = prepareData()
			csvExport(items)
		}

		const prepareData = () => {
			let items = [];

			state.users.forEach(dt => {
				let obj = {
					"ESTATUS": '',
					"NOMBRE": '',
					"APELLIDOS": '',
					"CORREO": dt.email.toLowerCase(),
					"TELEFONO": dt.telefono,
					"CEDULA": '',
					"ESTADO_ACTUAL": '',
					"ESTADO_NATAL": '',
					"ESPECIALIDAD": '',
					"GRADO": '',
					"INSTITUCION": '',
					"HA_SUBIDO_CASO": '',
					"PUNTOS_POR_CASO": '',
					"PUNTOS_POR_JUEGO": '',
					"TIEMPO_DE_JUEGO": '',
					"FECHA_DE_REGISTRO": ''
				};

				obj.ESTATUS = (dt.pending == true) ? 'PENDIENTE' : 'AUTORIZADO'
				if (dt.name == undefined) {
					obj.ESTATUS = 'FCR'
				} else {
					obj.NOMBRE = dt.name.toUpperCase()
					obj.APELLIDOS = dt.paterno.toUpperCase()
					obj.APELLIDOS += (dt.materno) ? ' ' + dt.materno.toUpperCase() : ''
					obj.TELEFONO = (dt.telefono) ? ' ' + dt.telefono : ''
					obj.CEDULA = dt.cedula
					obj.INSTITUCION += dt.institucion.toUpperCase()
					obj.GRADO = dt.grado.toUpperCase()
					obj.ESTADO_ACTUAL = Misc.estados.find(item => item.id == dt.estadoActual).text
					obj.ESTADO_NATAL = Misc.estados.find(item => item.id == dt.estadoNatal).text
					obj.ESPECIALIDAD = Misc.especialidades.find(item => item.id == dt.especialidad).text
					obj.PUNTOS_POR_CASO = dt.casePoints
					obj.HA_SUBIDO_CASO = (dt.case) ? 'Sí' : 'No'
					obj.PUNTOS_POR_JUEGO = dt.gamePoints
					obj.TIEMPO_DE_JUEGO = dt.timePlayed
					obj.FECHA_DE_REGISTRO = dt.createdAt.toDate().toLocaleString('es-MX', { day: '2-digit', month: 'long', year:'numeric' })
				}
			
				items.push(obj);
			});

			return items;
		}

		const csvExport = (arrData) => {
			let csvContent = "data:text/csv;charset=utf-8,";
			csvContent += [Object.keys(arrData[0]).join(";"), ...arrData.map(item => Object.values(item).join(";"))].join("\n").replace(/(^\[)|(\]$)/gm, "");

			const data = encodeURI(csvContent);
			const link = document.createElement("a");
			link.setAttribute("href", data);
			link.setAttribute("download", "Usuarios SAYL'S.csv");
			link.click();
		}

		return {
			tabs,
			state,
			setShow,
			autorizar,
			listUsers,
			downloadFile
		}
	},
}
</script>
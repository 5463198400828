import _ from 'lodash'

export default {

    sortBy(items, factor) {
        return items.sort( (b, a) => { 
                if (b[factor] == a[factor]) {
                    return (b[factor] < a[factor]) ? -1 : (b[factor] > a[factor]) ? 1 : 0;
                } else {
                    return (b[factor] < a[factor]) ? -1 : 1;
                }
            });
    },

    formatCellItem(item, max) {
        let limit_max = (max == undefined) ? 15 : max;

        if (item.length > limit_max) {
            return item.substr(0, limit_max) + '...';
        }

        return item;
    },

    formatQuery(query) {
        let items = [];

        query.forEach(dt => {
            let tempo = dt.data();
            tempo.id = dt.id;
            items.push(tempo);
        });

        return items;
    },

    formatItems(query, id='id', str='name', sort='asc') {
        let tempo = [];

        query.forEach(dt => {
            let u = dt.data();
            u[id] = dt.id;
            tempo.push(u);
        });

        return _.orderBy(tempo, str, sort);
    },

    quill: [
        [{ 'size': ['small', false, 'large', 'huge'] }],
        ['bold', 'italic', 'underline', 'link'],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'indent': '-1'}, { 'indent': '+1' }]
    ]

}